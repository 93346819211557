import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Learning App Template | Uizard"
    canonical="/templates/mobile-app-templates/learning-mobile-app/"
    category="Tablet Templates"
    url="/templates/tablet-templates/learning-tablet-app/"
    metaDescription="Want to create your own prototype of a learning tablet app? This design template features various fully customizable screens for a prototype of a learning app."
    description="
    h2:A learning app design template for tablet
    <br/>
    Want to create your own <a:https://uizard.io/mockups/>UI mockup</a> of a learning tablet app? This design template is the one for you. It features various fully customizable screens for a prototype of a learning app, meaning all you need to do is open the template and tweak it to suit your project.
    <br/>
    h3:E-learning app design made easy
    <br/>
    Want to build a prototype of language learning or a creative course? Our brand-new e-learning app design template has got you covered for any topic. All screens in the template are fully customizable and offer a huge range of components for all your learning app prototyping needs.
    <br/>
    h3:Design it your way!
    <br/>
    Change the text, add your photos, and adjust the theme according to your design preferences. Do you have a selection of <a:https://uizard.io/wireframing/>UI wireframes</a> you want to add to your prototype? Our magic editor is here to help you. Just upload your wireframe sketches and let the magic begin.
    "
    pages={[
      "Overview of course types",
      "User statistics overview",
      "Course preview and details",
      "Search directory",
    ]}
    projectCode="pbMX9WP9KqSY4Wvrmd7O"
    img1={data.image1.childImageSharp}
    img1alt="learning tablet app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="learning tablet app design template course screen"
    img3={data.image3.childImageSharp}
    img3alt="learning tablet app design template search screen"
    img4={data.image4.childImageSharp}
    img4alt="learning tablet app design template home screen"
    img5={data.image5.childImageSharp}
    img5alt="learning tablet app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/learning-tablet-app/learning-tab-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/learning-tablet-app/learning-tab-app-course.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/learning-tablet-app/learning-tab-app-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/learning-tablet-app/learning-tab-app-home.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/learning-tablet-app/learning-tab-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
